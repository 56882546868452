import styled from "@emotion/styled";
import {
  BalanceValue,
  JackPot,
} from "assets/images";
import { dimentions, sizes } from "service";
import { mixins } from "theme";

export const GameLayoutContainer = styled.div`
  position: relative;
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;

  .bet {
    min-width: 516px !important;
  }

  .balance-value {
    height: 33px !important;
  }

  .bet-value {
    max-width: 163px;
    height: 33px !important;
  }

  #bgVideo {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  .header-menu {
    width: 20px;
    display: flex;
    cursor: pointer;
    align-items: center;

    .ant-image-img {
      width: unset;
    }
  }
  .jackpot-wrapper {
    z-index: 2;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .free-star-left-wrapper {
      position: absolute;
      left: 0px;
      .free-star-left {
        position: relative;
        .free-left-value {
          position: absolute;
          border-radius: 6px;
          background-color: #fd3435;
          top: -2px;
          right: 0;
          width: 19px;
          height: 18px;
          text-align: center;
          color: white;
          font-size: 12px;
        }
      }
    }
    .free-celebration-wrapper {
      position: absolute;
      left: 35px;
    }
  }
  .rolling-container {
    user-select: none;
    position: relative;
    max-width: 516px;
    min-width: 516px;
    width: 516px;
    min-height: 830px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .ins-container {
      position: absolute;
      width: calc(100% + 30px);
      height: 100%;
      background: rgba(0, 0, 0, 0.9);
      display: flex;
      justify-content: center;
      flex-direction: column;
      z-index: 99;
      -webkit-transform: translate3d(0,0,1px);
      transform: translate3d(0,0,1px);
      top: 0;
      @media only screen and (min-width: 430px) {
        min-height: 100vh;
      }
      .ins-wrapper {
        position: relative;
        width: calc(100% - 30px);
        height: 100%;
        .btn-guide {
          z-index: 1;
          cursor: pointer;
          transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            transform 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            border-color 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            color 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            background-color 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            box-shadow 0.45s cubic-bezier(0.25, 1, 0.33, 1);
          &:hover {
            transform: translateY(-3px);
          }
          &.guide2 {
            top: 150px;
          }
          &.guide3 {
            top: 127px;
            left: 41px;
          }
          &.guide5 {
            top: 140px;
            left: 46px;
          }
        }
      }
    }

    .rolling-header {
      width: 90%;
      z-index: 2;
      height: 66px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .game-id {
        position: absolute;
        right: 0;
        top: -5px;
        font-family: Roboto;
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgba(255, 255, 255, 0.7);
      }
      .balance-value {
        flex: 3;
        height: 30px;
        background: url(${BalanceValue});
        background-size: 100% 100%;
        margin-left: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-left: 10px;
        span {
          flex: 1;
          text-align: center;
          font-family: Roboto;
          font-size: 18px !important;
        }
        .balance-value-title {
          font-size: 18px;
          color: #e1b353;
          font-weight: 700;
          font-family: "Roboto";
          filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
        }
      }
      .bet-value {
        flex: 3;
        height: 30px;
        background: url(${BalanceValue});
        background-size: 100% 100%;
        margin-left: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-left: 10px;
        span {
          flex: 1;
          text-align: center;
          font-size: 18px !important;
        }
        .bet-value-title {
          font-size: 18px;
          color: #e1b353;
          font-weight: 700;
          font-family: "Roboto";
          filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
        }

      }
      .game-number-container {
        height: 100%;
        display: flex;
        align-items: flex-end;
      }
      i {
        position: absolute;
        left: 0;
        top: 10px;
      }
    }
    .score-container {
      width: 100%;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: 5px;
      .left-side {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &.play {
          flex: 1;
        }
        .jack-bot {
          height: 70px;
          width: 100%;
          background: url(${JackPot});
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }
      .right-side {
        height: 100%;
        /* width: ${sizes.betWidth}px; */
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding-left: 10px;
      }
    }
    .total-earning {
      /* position: absolute;
      width: 118px;
      text-align: center;
      top: ${dimentions.totalTop}px;
      left: ${dimentions.totalLeft}px; */
    }
    .round-bonus {
      position: absolute;
      width: 118px;
      text-align: center;
      top: 159px;
      right: -22px;
    }
    .bet-text-animation {
      position: absolute;
      top: ${dimentions.additionalTop}px;
      font-size: 40px;
      padding: 0 33px;
      color: #ffff45;
      background: linear-gradient(
        360deg,
        #fbe741 25%,
        #dba323 53%,
        #fbe741 72%
      );
      -webkit-text-fill-color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      font-weight: 900;
      font-family: "Roboto";
      text-align: center;
      filter: drop-shadow(0px 10px 10px rgba(0, 71, 147, 0.5));
      text-transform: uppercase;
      z-index: 1001;
    }
    .bottom-guide-container {
      position: absolute;
      bottom: 40px;
      right: 0;
    }
    /* .instruction-container {
      width: 100%;
      position: absolute;
      bottom: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 10px;
      border-radius: 10px;
      .toggle-wrapper-md {
        display: flex;
        flex-direction: column;
        align-items: center;
        h5 {
          color: white;
          font-size: 14px;
        }
      }
      .toggle-wrapper-sm {
        display: none;
      }
      .icon-music {
        font-size: 34px;
        color: white;
        cursor: pointer;
      }
      @media (max-height: 870px) {
        bottom: 25px;
      }
      @media (max-height: 820px) {
        bottom: 0px;
      }
    } */
  }

  @media (max-width: ${mixins.mobileWidth}px),
    (max-height: ${mixins.ipadHeight}px) {
    padding-bottom: 0px;
    .rolling-container {
      padding-top: 10px;
      .rolling-header {
        .game-id {
          top: -10px;
        }
        .jack-bot {
          width: 160px;
          span {
            font-size: 16px !important;
          }
        }
        .coin {
          width: 155px;
          height: 55px;
          background-size: 100% 100%;
        }
        .game-number-container {
          .game-number {
            font-size: 9px !important;
          }
        }
      }
      .score-container {
        width: ${sizes.mainWidthM}px;
        height: 50px;
        margin-top: 0px;
        .jack-bot {
          width: ${sizes.mainWidthM}px;
          background-size: 100% 100%;
          span {
            font-size: 20px !important;
          }
        }
        .scores {
          .score-card {
            width: 120px;
          }
        }
        .left-side {
          .coin {
            width: 160px;
          }
        }
      }
      /* .instruction-container {
        padding: 0 10px;
        .toggle-wrapper-md {
          display: none;
        }
        .toggle-wrapper-sm {
          display: block;
        }
      } */
      /* .total-earning {
        width: 100px;
        left: 142px;
        span {
          font-size: 18px !important;
        }
      } */
      .bet-text-animation {
        font-size: 22px !important;
      }
    }
  }
  @media (max-height: 1100px) {
    align-items: center;
  }
`;

export const StrikeAnimationContainer = styled.div`
  position: absolute;
  top: 480px;
  z-index: ${(props) => (props.show777 ? 1 : 0)};
  display: ${(props) => (props.show777 ? 'block' : 'none')};
  transform: translateX(-45px);
  /* width: 100%; */
`;

export const LineWinAnimationContainer = styled.div`
  ${props => !props.show ? `
    width: 0;
    height: 0;
    opacity: 0;
  `: ''}
`

export const JackpotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .full-card {
    width: 110px;
  }
`;

export const BottomContents = styled.div`
  position: relative;
  width: 98%;
  max-width: 460px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 50px;
  height: 150px;
`;

export const SideBetContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  &::nth-child(2) {
    justify-content: end;
  }
`;